<template>
<div>
  <vx-card>
    <div>
      اضافه کردن اعلان جدید
    </div>
    <div class="mt-5">
      <div class="mt-2 flex">
        <vs-input class="inputx m-2" placeholder="عنوان پیام " v-model="title"/>
        <input ref="fileupload" class="inputx m-2"  type="file"/>
      </div>
      <div class="mt-2">
        <div>
          <vs-textarea label="متن پیام " v-model="text" />
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <vs-button @click="createTodo" color="primary" type="filled">ایجاد</vs-button>
    </div>
    <vs-divider />
    <div class="mt-2 mb-2 justify-center flex">
      <h3>
        لیست اعلانات داخلی
      </h3>
    </div>
    <br />
    <vs-table :data="users">
      <template slot="thead">
        <vs-th>
          عنوان
        </vs-th>
        <vs-th>
          متن
        </vs-th>
        <vs-th>
          عملیات
        </vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].title">
            {{data[indextr].title}}
          </vs-td>
          <vs-td :data="data[indextr].text">
            {{data[indextr].text}}
          </vs-td>
          <vs-td :data="data[indextr].id">
            <vs-button @click="deleteTodo(data[indextr].id)" class="m-2" color="danger" icon="delete" size="small" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</div>
</template>

<script>
export default {
  name: 'SalesTodo',
  data () {
    return {
      users:[],
      title: '',
      text: '',
      file:''
    }
  },
  methods: {
    onFileChange(e){
      console.log(e.target.files[0]);
      this.file = e.target.files[0];
    },
    deleteTodo (id) {
      this.$http.delete(`admin/salestodo/${id}`).then((res) => {
        this.$vs.notify({ text: res.data.message, color: "success" });
        this.getTodosList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getTodosList () {
      this.$http.get('admin/salesreport').then((res) => {
        this.users = res.data.params
      }).catch((err) => {
        console.log('err', err)
      })
    },
    createTodo () {
      const tododata = {
        title : this.title,
        text : this.text,
        file : this.$refs.fileupload.files[0]
      }
      let form_data = null
      console.log(tododata.file)
      if (tododata.file) {
        form_data = new FormData()

        for (const key in tododata) {
          form_data.append(key, tododata[key])
        }

      } else {
        form_data = new FormData()

        for (const key in tododata) {
          form_data.append(key, tododata[key])
        }
        form_data.delete('file')

      }
      console.log('form_dataform_data', form_data)
      this.$http.post('admin/salestodo/store',
        form_data,
        {headers: {'Content-Type': 'multipart/form-data' }}
      ).then((res) => {
        this.$vs.notify({ text: res.data.message, color: "success" });
        console.log('res', res)
      }).catch((err) => {
        console.log('err', err)
      })
    }
  },
  mounted() {
    this.getTodosList()
  }
}
</script>

<style scoped>

</style>
